import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import WorkHoursSection from "./sections/WorkHoursSection";
import {
  createJob,
  createJobImage,
  deleteJobImage,
  getJob,
  updateJob,
} from "../../apis/job";
import {Job, JobExtended} from "../../types/api";
import Text from "../../../core/components/Text";
import Button from "../../../core/components/Button";
import Page from "../../../core/components/Page";
import ACTIONS from "../../../core/constants/actions";
import { useReducerContext } from "../../../core/contexts/ReducerContext";
import { JobContractPeriod, JobSalaryType, JobState } from "../../enums/job";
import { BasicInformationSection } from "./sections/BasicInformationSection";
import styled from "styled-components";
import SalarySection from "./sections/SalarySection";
import CandidateConditionsSection from "./sections/CandidateConditionsSection";
import HolidaysVacationSection from "./sections/HolidaysVacationSection";
import LivingEnvironmentSection from "./sections/LivingEnvironmentSection";
import ResignationOtherSection from "./sections/ResignationOtherSection";
import Column from "../../../core/components/Column";
import { JobPostImage } from "../../types/jobPost";
import { isEmpty } from "../../../core/utils";
import { ReactComponent as RightArrowIcon } from "../../../assets/icon-forward.svg";
import ExtraWagesSection from "./sections/ExtraWagesSection";
import { useNationalities } from "../../../core/hooks";
import AiAnalysisSection from "./sections/AiAnalysisSection";
import { ReactComponent as AlertIcon } from "../../../assets/icon-alert.svg";
import { Row20 } from "./commonStyle";
import Accordion from "../../../core/components/Accordion";
import SupportPersonInformationSection from "./sections/SupportPersonInformationSection";
import {prepareJobSupportData} from "./jobSupportPreparation";
import CompanyInfoSection from "./sections/CompanyInfoSection";
import InfoAboutEmploymentSection from "./sections/InfoAboutEmploymentSection";
import { prepareInitializedJobFormData } from "./JobFormInitialization";

interface JobFormPageProps {}

interface ForwardIconProps {
  disabled?: boolean;
}

const ContentContainer = styled(Column)`
  width: 100%;
  gap: 40px;
`;

const HeadingContainer = styled(Column)`
  width: 100%;
  gap: 20px;
`;

const MainHeading = styled.h2`
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;

const Block = styled.div`
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--Grey-40, #c2c2c2);
  background-color: white;
`;

const AccordionBlock = styled(Accordion)``;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const ForwardIcon = styled(RightArrowIcon)<ForwardIconProps>`
  path {
    fill: ${({ disabled }) => (disabled ? "#FFC194" : "")};
  }
`;

const PublishButton = styled(Button)`
  gap: 10px;

  &:hover {
    path {
      fill: ${({ disabled }) => (disabled ? "#FFC194" : "")};
    }
  }
`;

const NoteInHeading = styled(Text)`
  font-size: 12px;
  color: #8a8e94;
`;

const AlertBanner = styled.div`
  width: 100%;
  background-color: #FFE5E5;
  padding: 15px;
  border-radius: 5px;
  
  & * {
    color: #E93232;
    fill: #E93232;
  }
`;

const JobFormPage: FunctionComponent<JobFormPageProps> = () => {
  const { dispatch, state: reducerState } = useReducerContext();
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { nationalityOptions } = useNationalities();
  const [searchParams] = useSearchParams();
  const [jobData, setJobData] = useState<Partial<JobExtended>>();
  const [jobPostImages, setJobPostImages] = useState<JobPostImage[]>([]);
  const [jobId, setJobId] = useState<string | null>(useParams().jobId ?? null);
  const [aiAnalysisNeverSucceededWhenLoaded, setAiAnalysisNeverSucceededWhenLoaded] = useState<boolean>(); 
  const isDraft = !!!jobId || jobData?.state === JobState.Draft;
  const isPublished = jobData?.state === JobState.Published;
  const isRegistered = !!jobId;
  const AI_ANALYSIS_ATTEMPT_LIMIT = 10;
  const totalAttempts = (jobData?.aiAnalyzeSuccessCount ?? 0) + (jobData?.aiAnalyzeFailureCount ?? 0);
  const analysisByAiNeverAttempted = totalAttempts === 0
  const reachedAiAnalyzeLimit =  (
    (jobData?.aiAnalyzeSuccessCount ?? 0) + 
    (jobData?.aiAnalyzeFailureCount ?? 0)
    ) >= AI_ANALYSIS_ATTEMPT_LIMIT;

  const onChange = (val: Partial<JobExtended>) => {
    setJobData((prevState) => ({ ...prevState, ...val }));
  };
  const commonProps = useMemo(() => ({ job: jobData, onChange }), [jobData]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isFile = (JobPostImage: JobPostImage): JobPostImage is File => {
    return typeof JobPostImage === "object";
  };

  const isMissingRequiredField = useMemo(() => {
    const conditionallyRequiredFields = [];

    //Basic Information section
    if (jobData?.ecContractPeriodRenewal === JobContractPeriod.FixedTerm)
      conditionallyRequiredFields.push(jobData?.contractPeriodYears);

    //Salary section
    if (
      jobData?.salaryType === JobSalaryType.Daily ||
      jobData?.salaryType === JobSalaryType.Hourly
    )
      conditionallyRequiredFields.push(jobData?.hourlyDailySalary);

    const requiredFields = [
      //Basic Information section
      jobData?.name,
      jobData?.numberOfPositions,
      jobData?.jobTypeId,
      jobData?.contractPeriod,
      jobData?.japaneseConversationSkills,
      jobData?.jobDescription,

      //Support Person Information section
      //(N/A)

      //Company Information section
      jobData?.ecEmployerEnOrganizationName,
      jobData?.ecEmployerPostalCode,
      jobData?.ecEmployerJaPrefecture,
      jobData?.ecEmployerEnPrefecture,
      jobData?.ecEmployerJaCityWard,
      jobData?.ecEmployerEnCityWard,
      jobData?.ecEmployerJaTown,
      jobData?.ecEmployerEnTown,
      jobData?.ecEmployerJaAddressNumber,
      jobData?.ecEmployerEnAddressNumber,
      jobData?.ecEmployerPhoneNumber,

      //Information about Employment section
      jobData?.ecPlaceOfEmploymentPostalCode,
      jobData?.ecPlaceOfEmploymentJaPrefecture,
      jobData?.ecPlaceOfEmploymentEnPrefecture,
      jobData?.ecPlaceOfEmploymentJaCityWard,
      jobData?.ecPlaceOfEmploymentEnCityWard,
      jobData?.ecPlaceOfEmploymentJaTown,
      jobData?.ecPlaceOfEmploymentEnTown,
      jobData?.jaAddressNumber,
      jobData?.enAddressNumber,

      //Salary section
      jobData?.salaryType,
      jobData?.grossSalary,

      //Candidate Conditions section
      //(N/A)

      //Extra Wages section
      //(N/A)

      //Work Hours section
      //(N/A)

      //Holidays & Vacation section
      //(N/A)

      //Living Environment section
      //(N/A)

      //Resignation & Other section
      jobData?.interviewMethod,
      jobData?.availableDaysAndTimesForInterviews,

      ...conditionallyRequiredFields,
    ];

    return requiredFields.some(isEmpty);
  }, [jobData]);

  const updateJobData = async (state: JobState) => {
    if (!jobId) return;

    if (isMissingRequiredField && state === JobState.Published) {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: `
            <b>必須項目エラー</b><br />
            必須項目が全て入力・選択されていないため、更新できません。<br />
            必須項目を確認してください
          `,
        },
      });
      return;
    }

    dispatch({
      type: ACTIONS.START_LOADING,
      payload: {
        message: t(`job.updating_job`),
      },
    });

    try {
      await updateJob(jobId, {
        ...jobData,
        ...prepareJobSupportData({company: reducerState.company!}),
        state,
      });

      jobPostImages
        .filter(isFile)
        .forEach(async (imageFile) => await createJobImage(jobId, imageFile));

      jobData?.images?.forEach(async (image) => {
        if (jobPostImages.includes(image.url)) return;

        await deleteJobImage(image.id);
      });

      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "success",
          message: t(`job.job_update_success`),
        },
      });

      navigate("/jobs");
    } catch (e) {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: t(`job.job_update_failed`),
        },
      });
    }
    dispatch({
      type: ACTIONS.STOP_LOADING,
    });
  };

  const createNewJobPost = async (state: JobState = JobState.Draft) => {
    const job = await createJob({
      ...jobData,
      ...prepareJobSupportData({company: reducerState.company!}),
      state
    });

    jobPostImages.filter(isFile).forEach(async (imageFile) => {
      await createJobImage(job.id, imageFile);
    });

    setJobId(job.id.toString());
    return job;
  }

  const registerJob = async (state: JobState = JobState.Draft) => {
    if (isMissingRequiredField && state === JobState.Published) {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: `
            <b>必須項目エラー</b><br />
            必須項目が全て入力・選択されていないため、公開できません。<br />
            必須項目を確認してください
          `,
        },
      });
      return;
    }

    dispatch({
      type: ACTIONS.START_LOADING,
      payload: {
        message: t("job.creating_job"),
      },
    });

    try {
      createNewJobPost(state);

      let successMessage = "";
      
      switch (state) {
        case JobState.Draft:
          successMessage = "求人票が下書き保存されました";
          break;
        case JobState.Published:
          successMessage = t(`job.job_registration_success`);
          break;
      }

      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "success",
          message: successMessage,
        },
      });

      navigate("/jobs");
    } catch (e) {
      let errorMessage = "";

      switch (state) {
        case JobState.Draft:
          errorMessage = "求人票の下書き保存に失敗しました";
          break;
        case JobState.Published:
          errorMessage = t(`job.job_registration_failed`);
          break;
      }

      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: errorMessage,
        },
      });
    }
    dispatch({
      type: ACTIONS.STOP_LOADING,
    });
  };

  useEffect(() => {
    //When a job post is copied based on an existing job post,
    //the reference job ID is passed through 'from' parameter
    const jobIdToCopyFrom = searchParams.get("from");
    const jobIdToFetch = jobId || jobIdToCopyFrom;
    const isJobPostCopied = !!jobIdToCopyFrom;

    (async () => {
      dispatch({
        type: ACTIONS.START_LOADING,
        payload: {
          message: t("job.fetching_job_details"),
        },
      });

      
      try {
        if (!jobIdToFetch) {
          const initData = await prepareInitializedJobFormData({
            company: reducerState.company
          });
  
          setJobData({ 
            state: JobState.Draft,
            ...initData,
          });
          setAiAnalysisNeverSucceededWhenLoaded(true);
          return;
        }

        const job: Job = await getJob(jobIdToFetch);
        let jobDataToLoad: Partial<Job> = job;

        if (!jobIdToCopyFrom && job.state === JobState.Archived) {
          navigate("/jobs");
          return;
        }

        if (isJobPostCopied) {
          jobDataToLoad = await prepareInitializedJobFormData({
            company: reducerState.company,
            refJob: job
          })
          delete jobDataToLoad.id; 
          delete jobDataToLoad.updatedAt;
          delete jobDataToLoad.createdAt;
          jobDataToLoad.state = JobState.Draft;
          jobDataToLoad.aiAnalyzeSuccessCount = 0;
          jobDataToLoad.aiAnalyzeFailureCount = 0;

          /* Don't share the image files of the original job post with the copied job post 
             because if one of them is deleted, it will be deleted from the original job post */
        } else {
          setJobPostImages(job?.images?.map((image) => image.url) ?? []);
        }
        
        setJobData({
          ...jobDataToLoad,
          preferredNationalityIds: job?.preferredNationalities?.map(
            (pn) => pn.id
          ) ?? [],
          currentNationalityIds: job?.currentNationalities?.map((cn) => cn.id) ?? [],
          bonusMonthIds: job?.bonusMonths?.map((bm) => bm.id) ?? [],
        });

        setAiAnalysisNeverSucceededWhenLoaded(
          (job.aiAnalyzeSuccessCount === 0) ||
          (job.aiAnalyzeSuccessCount == null)
        )
      } catch (e) {
        dispatch({
          type: ACTIONS.SET_PROMPT,
          payload: {
            type: "warning",
            message: "エラーが発生しました",
          },
        });
      } finally {
        dispatch({
          type: ACTIONS.STOP_LOADING,
        });
      }
      
    })();
  }, []);

  return (
    <Page>
      <ContentContainer>
        <HeadingContainer>
          <MainHeading>
            {isDraft && t("job.create_job_post")}

            {/* TODO: Add a translation later */}
            {isPublished && "求人票を編集"}
          </MainHeading>

          <ButtonsContainer>
            {isPublished &&
              <Button
                onClick={() => updateJobData(JobState.Published)}
              >
                {t("core.save")}
              </Button>
            }

            {isDraft && (
              <>
                <Button
                  variant="secondary"
                  onClick={() =>
                    isRegistered
                      ? updateJobData(JobState.Draft)
                      : registerJob(JobState.Draft)
                  }
                >
                  {t("job.save_as_draft")}
                </Button>
                <PublishButton
                  onClick={() =>
                    isRegistered
                      ? updateJobData(JobState.Published)
                      : registerJob(JobState.Published)
                  }
                  style={{ gap: 10 }}
                >
                  {t("job.publish")}
                  {<ForwardIcon />}
                </PublishButton>
              </>
            )}
          </ButtonsContainer>
        </HeadingContainer>
        
        { !!jobData && isDraft && reachedAiAnalyzeLimit && 
          <Block>
            <AlertBanner>
              <Row20>
                <AlertIcon />
                <Text>
                  <strong>この求人はデータ取り込み上限に達しています。</strong>
                </Text>
              </Row20>
            </AlertBanner>
          </Block>
        }

        { !!jobData && isDraft && !reachedAiAnalyzeLimit &&
          <AccordionBlock
              active={aiAnalysisNeverSucceededWhenLoaded}
              headingText={"既存の求人票データを利用して作成"}
              additionalComponentInHeading={
                analysisByAiNeverAttempted
                  ? <NoteInHeading>
                      ※求人票の取り込みが可能です
                    </NoteInHeading>

                  : <NoteInHeading style={{ color: "#E93232" }}>
                      ※求人票は{totalAttempts}回取り込みを行っています
                    </NoteInHeading>
              }
          >
              <AiAnalysisSection 
                createJobPost={() => createNewJobPost()}
                analysisAttemptLimit={AI_ANALYSIS_ATTEMPT_LIMIT}
                {...commonProps} 
              />
          </AccordionBlock>
        }
        
        <Block>
          <BasicInformationSection {...commonProps} />
        </Block>
        <Block>
          <SupportPersonInformationSection {...commonProps} />
        </Block>
        <Block>
          <CompanyInfoSection {...commonProps} />
        </Block>
        <Block>
          <InfoAboutEmploymentSection {...commonProps} />
        </Block>
        <Block>
          <SalarySection {...commonProps} />
        </Block>
        
        <AccordionBlock headingText={tJob("candidate_requirements")}>
          <CandidateConditionsSection
            nationalityOptions={nationalityOptions ?? []}
            {...commonProps}
          />
        </AccordionBlock>
        
        <AccordionBlock headingText={tJob("overtime_pay_rate")}>
          <ExtraWagesSection {...commonProps} />
        </AccordionBlock>
      
        <AccordionBlock headingText={tJob("job_working_hours")}>
          <WorkHoursSection {...commonProps} />
        </AccordionBlock>
        
        <AccordionBlock headingText={tJob("holidays_vacation")}>
          <HolidaysVacationSection {...commonProps} />
        </AccordionBlock>
      
        <AccordionBlock headingText={tJob("housing")}>
          <LivingEnvironmentSection {...commonProps} />
        </AccordionBlock>
      
        <Block>
          <ResignationOtherSection
            nationalityOptions={nationalityOptions ?? []}
            jobPostImages={jobPostImages}
            onJobPostImagesChange={setJobPostImages}
            {...commonProps}
          />
        </Block>
        <ButtonsContainer>
          {isPublished ? (
            <Button
              onClick={() => updateJobData(JobState.Published)}
            >
              {t("core.save")}
            </Button>
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={() =>
                  isRegistered
                    ? updateJobData(JobState.Draft)
                    : registerJob(JobState.Draft)
                }
              >
                {t("job.save_as_draft")}
              </Button>
              <Button
                onClick={() =>
                  isRegistered
                    ? updateJobData(JobState.Published)
                    : registerJob(JobState.Published)
                }
                style={{ gap: 10 }}
              >
                {t("job.publish")}
                {<ForwardIcon  />}
              </Button>
            </>
          )}
        </ButtonsContainer>
      </ContentContainer>
    </Page>
  );
};

export default JobFormPage;
