import { getAddressInformation } from "../../../core/apis/address";
import { Company } from "../../../core/types/api";
import { isEmpty, isValidPostalCode, sanitizePostalCode } from "../../../core/utils";
import { Job } from "../../types/api";

type Data = {
  company?: Company | null;
  refJob?: Job | null;
}

export const prepareInitializedJobFormData = async (data: Data): Promise<Partial<Job>> => {
  const { company, refJob } = data;
  const initVals: Partial<Job> = { ...refJob };
  const employer = company?.employer!;

  if (!company)
    return {};

  const assignNewValueIfEmpty = <K extends keyof Job>(
    key: K,
    value: Job[K]
  ) => {
    if (isEmpty(initVals[key])) {
      initVals[key] = value;
    }
  };

  /* For 企業情報 / Company information section */
  
  /* 会社/企業名 / Company name */
  assignNewValueIfEmpty('ecEmployerJaOrganizationName', company.jaName ?? '');
  assignNewValueIfEmpty('ecEmployerEnOrganizationName', company.enName ?? '');

  /* 郵便番号 / Postal code */
  /* the company's postal code might include a hyphen 
     while other places don't accept it, so we need to sanitize it */
  let companyAddrInfo = null;
  const companyPostalCode = sanitizePostalCode(company.postalCode);
  assignNewValueIfEmpty('ecEmployerPostalCode', companyPostalCode ?? '');

  if (isValidPostalCode(companyPostalCode)) {
    try {
      companyAddrInfo = await getAddressInformation(companyPostalCode!);
    } catch {
      //Probably, no need to handle error
    }
  }

  
  /* 都道府県 / Prefecture */
  assignNewValueIfEmpty('ecEmployerJaPrefecture', companyAddrInfo?.jaPrefecture ?? '');
  assignNewValueIfEmpty('ecEmployerEnPrefecture', companyAddrInfo?.enPrefecture ?? '');

  /* 市区 / City */
  assignNewValueIfEmpty('ecEmployerJaCityWard', company.jaCityWard ?? '');
  assignNewValueIfEmpty('ecEmployerEnCityWard', company.enCityWard ?? '');

  /* 町村 / Town */
  assignNewValueIfEmpty('ecEmployerJaTown', company.jaTown ?? '');
  assignNewValueIfEmpty('ecEmployerEnTown', company.enTown ?? '');

  /* 番地 / Street address */
  assignNewValueIfEmpty('ecEmployerJaAddressNumber', company.jaAddressNumber ?? '');
  assignNewValueIfEmpty('ecEmployerEnAddressNumber', company.enAddressNumber ?? '');

  /* 建物名等 / Building name */
  assignNewValueIfEmpty('ecEmployerJaBuilding', company.jaBuildingName ?? '');
  assignNewValueIfEmpty('ecEmployerEnBuilding', company.enBuildingName ?? '');

  /* 電話番号 / Phone number */ 
  assignNewValueIfEmpty('ecEmployerPhoneNumber', company.phoneNumber ?? '');

  /* 代表者名 / Representative name */
  assignNewValueIfEmpty('ecEmployerJaRepresentativeName', company.jaRepresentativeName ?? '');
  assignNewValueIfEmpty('ecEmployerEnRepresentativeName', company.enRepresentativeName ?? '');

  /* 代表者役職 / Representative position */
  assignNewValueIfEmpty('ecEmployerJaRepresentativeTitle', company.jaRepresentativeTitle ?? '');
  assignNewValueIfEmpty('ecEmployerEnRepresentativeTitle', company.enRepresentativeTitle ?? '');

  /* For 就業に関する情報 / Employment information section */
  /* 事務所名 */
  assignNewValueIfEmpty('ecPlaceOfEmploymentJaOfficeName', company.jaName ?? '');
  assignNewValueIfEmpty('ecPlaceOfEmploymentEnOfficeName', company.enName ?? '');

  /* 郵便番号 / Postal code */
  assignNewValueIfEmpty('postalCode', sanitizePostalCode(company.postalCode) ?? '');
  assignNewValueIfEmpty('ecPlaceOfEmploymentPostalCode', sanitizePostalCode(company.postalCode) ?? '');

  /* 都道府県 / Prefecture */
  assignNewValueIfEmpty('ecPlaceOfEmploymentJaPrefecture', companyAddrInfo?.jaPrefecture ?? '');
  assignNewValueIfEmpty('ecPlaceOfEmploymentEnPrefecture', companyAddrInfo?.enPrefecture ?? '');

  /* 市区 / City */
  assignNewValueIfEmpty('ecPlaceOfEmploymentJaCityWard', company.jaCityWard ?? '');
  assignNewValueIfEmpty('ecPlaceOfEmploymentEnCityWard', company.enCityWard ?? '');

  /* 町村 / Town */
  assignNewValueIfEmpty('ecPlaceOfEmploymentJaTown', company.jaTown ?? '');
  assignNewValueIfEmpty('ecPlaceOfEmploymentEnTown', company.enTown ?? '');

  /* 番地 / Street address */
  assignNewValueIfEmpty('jaAddressNumber', company.jaAddressNumber ?? '');
  assignNewValueIfEmpty('enAddressNumber', company.enAddressNumber ?? '');
  assignNewValueIfEmpty('ecPlaceOfEmploymentJaAddressNumber', company.jaAddressNumber ?? '');
  assignNewValueIfEmpty('ecPlaceOfEmploymentEnAddressNumber', company.enAddressNumber ?? '');

  /* 建物名等 / Building name */
  assignNewValueIfEmpty('jaBuildingName', company.jaBuildingName ?? '');
  assignNewValueIfEmpty('enBuildingName', company.enBuildingName ?? '');
  assignNewValueIfEmpty('ecPlaceOfEmploymentJaBuilding', company.jaBuildingName ?? '');
  assignNewValueIfEmpty('ecPlaceOfEmploymentEnBuilding', company.enBuildingName ?? '');

  /* 電話番号 / Phone number */
  assignNewValueIfEmpty('ecPlaceOfEmploymentPhoneNumber', company.phoneNumber ?? '');

  /* 賞与 / Bonus */
  assignNewValueIfEmpty('bonus', employer.bonus);

  
  /* For 給与 / Salary section */
  /* 締切日/支払日1 */
  assignNewValueIfEmpty('ecCompensationPayrollCutOffDate_1', employer.compensationPayrollCutOffDate_1);
  assignNewValueIfEmpty('ecCompensationPayrollDate_1', employer.compensationPayrollDate_1);

  /* 締切日/支払日2 */
  assignNewValueIfEmpty('ecCompensationPayrollCutOffDate_2', employer.compensationPayrollCutOffDate_2);
  assignNewValueIfEmpty('ecCompensationPayrollDate_2', employer.compensationPayrollDate_2);
  

  return initVals;
}